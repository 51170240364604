import React from "react"

import type { DMImportedImage } from "../../types/image"
import { cn } from "../../utils/cn"

import { cva, type VariantProps } from "class-variance-authority"

const imageStyles = cva("max-w-full block", {
  variants: {
    displayMode: {
      cover: "object-cover",
      contain: "object-scale-down",
    },
  },
  defaultVariants: {
    displayMode: "cover",
  },
})

export type DMImageProps = Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  "src"
> &
  VariantProps<typeof imageStyles> & {
    src?: string
    /**
     * @deprecated Use `src` instead. This prop will be removed in a future version.
     */
    image?: DMImportedImage
    alt?: string
    width?: string | number
    height?: string | number
    onLoad?: (e: React.SyntheticEvent) => void
    onError?: (e: React.SyntheticEvent) => void
    fallback?: string | React.ReactNode | boolean
    className?: string
  }

const fallbackStyles =
  "flex items-center justify-center bg-neutral-faded text-neutral-faded"

// Add this utility function before the DMImage component
const toPxIfNumber = (
  value: string | number | undefined
): string | undefined => {
  if (typeof value === "number") {
    return `${value}px`
  }
  return value ?? undefined
}

function DMImage({
  src,
  image,
  alt,
  width,
  height,
  onLoad,
  onError,
  fallback,
  displayMode,
  className,
  ...props
}: DMImageProps) {
  const [status, setStatus] = React.useState<"loading" | "success" | "error">(
    "loading"
  )

  // Determine the source, width, and height based on the props
  const imgSrc = src || (typeof image === "string" ? image : image?.src)
  const imgWidth =
    width || (typeof image === "object" ? image.width : undefined)
  const imgHeight =
    height || (typeof image === "object" ? image.height : undefined)

  React.useEffect(() => {
    setStatus("loading")
  }, [imgSrc])

  const isFallback = status === "error" || !imgSrc

  const style: React.CSSProperties = {
    width: toPxIfNumber(imgWidth),
    height: toPxIfNumber(imgHeight),
    ...props.style,
  }

  if (isFallback) {
    // If fallback is truthy (ReactNode or true), show fallback container
    if (fallback) {
      return (
        <div className={cn(fallbackStyles, className)} style={style} {...props}>
          {fallback === true ? null : fallback}
        </div>
      )
    }

    // If no fallback provided, show empty container
    return (
      <div className={cn(fallbackStyles, className)} style={style} {...props} />
    )
  }

  return (
    <img
      src={imgSrc}
      alt={alt || ""}
      role={alt ? undefined : "presentation"}
      onLoad={(e) => {
        setStatus("success")
        onLoad?.(e)
      }}
      onError={(e) => {
        setStatus("error")
        onError?.(e)
      }}
      className={cn(imageStyles({ displayMode }), className)}
      style={style}
      width={imgWidth}
      height={imgHeight}
      loading="lazy"
      decoding="async"
      {...props}
    />
  )
}

DMImage.displayName = "DMImage"

export { DMImage }
